$fs-orange-100: #ff7800;
$fs-orange-90: #e66b00;
$fs-orange-80: #cc5f00;
$fs-orange-70: #b35300;
$fs-orange-60: #994700;
$fs-purple: #8264e3;
$fs-yellow-100: #ffb80f;
$fs-blue-100: #76b6ff;
$fs-blue-85: #1671d9;
$fs-blue-80: #146acc;
$fs-blue-70: #125db3;
$fs-darkblue: #1365c2;
$fs-brown-80: #ccad91;
$fs-brown-40: #665d55;
$fs-brown-35: #59514a;
$fs-brown-30: #4d463f;
$fs-brown-25: #40362d;
$fs-brown-20: #332b24;
$fs-green-90: #85e695;
$fs-green-80: #52cc66;
$fs-green-70: #36b34a;
$fs-green-60: #2e9940;
$fs-green-50: #268035;
$fs-red-80: #cc1237;
$fs-red-20: #ffccd6;
$fs-gray-30: #4d4d4d;

// override default bootstrap theme colors
$primary: $fs-blue-85;
$secondary: $fs-brown-40;
$success: $fs-green-50;
$danger: $fs-red-80;
$warning: $fs-yellow-100;
$info: $fs-orange-80;
$dark: $fs-brown-25;

.text-fs-orange {
  color: $fs_orange-100;
}

// @import "bootstrap/dist/css/bootstrap.min.css";
@import "~bootstrap/scss/bootstrap.scss";
